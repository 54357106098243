/* Typography */
.h1 {
  @apply text-3xl font-extrabold leading-tight tracking-tighter mb-5;
}

.h2 {
  @apply text-2xl font-extrabold leading-tight tracking-tighter mb-4;
}

.h3 {
  @apply text-xl font-bold leading-tight mb-3;
}

.h4 {
  @apply text-lg font-bold leading-snug tracking-tight mb-3;
}

@screen md {
  .h1 {
    @apply text-5xl;
  }

  .h2 {
    @apply text-4xl;
  }
}

/* Forms */
.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
  @apply bg-white border border-gray-300 focus:border-gray-500;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
  @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
  @apply py-3 px-4;
}

.form-input,
.form-textarea {
  @apply placeholder-gray-500;
}

.form-select {
  @apply pr-10;
}

.form-checkbox,
.form-radio {
  @apply text-gray-800 rounded-sm;
}

.navbar-link {
  @apply text-blue-900 px-4 py-2 flex md:items-center;
}

.navbar-link.active, .navbar-link.active:hover {
  @apply cursor-default text-gray-600 dark:text-gray-200;
}

.empty {
  @apply flex flex-row justify-center items-center text-gray-500 dark:text-gray-300 w-full p-2;
}
