@import "antd/dist/antd.min.css";

.ant-divider:before, .ant-divider:after {
  @apply dark:border-gray-400;
}

.ant-divider-inner-text {
  @apply dark:text-gray-50;
}

.ant-message .anticon {
  @apply align-top;
  line-height: 1;
}

.ant-switch {
  background: #616774 !important;
}

.ant-switch.ant-switch-checked {
  background: #194CFF !important;
}

.ant-switch .ant-switch-handle:before {
  background: #FFFFFF;
}
