@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');
@import '@solana/wallet-adapter-react-ui/styles.css';
@import 'antd.css';
@import 'loading.css';
@import 'unility.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.wallet-adapter-button {
  @apply py-0 px-2 h-8 transition bg-gray-800 hover:bg-gray-500 text-gray-100 hover:text-gray-50;
}

@layer base {
  * {
    /*outline: 1px solid rgba(0 255 0 / 0.4);*/
  }

  a {
    @apply text-gray-500 hover:text-gray-900 dark:text-gray-50 dark:hover:text-gray-400 transition;
  }

  body {
    @apply bg-gray-50 text-gray-900 dark:bg-black dark:text-gray-100;
    @apply min-h-screen break-words;
    font-family: Inter, sans-serif !important;
    color: #292E3E;
  }

  h1, h2, h3, h4, h5, h6, label, p {
    @apply text-gray-900 dark:text-gray-100;
  }
}

@layer components {
  .btn, .btn-sm {
    @apply font-medium inline-flex items-center justify-center rounded leading-snug transition duration-150 ease-in-out border align-top leading-none;
    @apply border-gray-400 text-gray-800 hover:text-gray-500;
    @apply dark:bg-gray-700 dark:border-gray-700 dark:text-gray-50 dark:hover:bg-gray-500 dark:hover:border-gray-500 dark:hover:text-gray-100;
  }

  .btn.orange, .btn-sm.orange {
    @apply text-gray-50 hover:text-gray-100 bg-orange-400 hover:bg-orange-500 dark:hover:text-gray-300 border-orange-400 hover:border-orange-500;
  }

  .btn.orange, .btn-sm.orange {
    @apply text-gray-50 hover:text-gray-100 bg-orange-400 hover:bg-orange-500 dark:hover:text-gray-300 border-orange-400 hover:border-orange-500;
  }

  .btn {
    @apply px-5 py-3;
  }

  .btn[disabled] {
    @apply opacity-70 cursor-default hover:text-gray-50 hover:bg-orange-400;
  }

  .btn-sm {
    @apply px-4 py-2;
  }

  .btn-sm.icon {
    @apply px-1 w-8 h-8;
  }

  .btn-black {
    @apply btn bg-[#000] text-white hover:bg-[#000] hover:text-white hover:border-black uppercase font-bold  h-[40px];
    @apply focus:text-white focus:bg-[#000] focus:border-black;
  }

  .btn-white {
    @apply btn bg-[#fff] text-black hover:bg-[#fff] hover:text-black hover:border-black uppercase font-bold  h-[40px];
    @apply focus:text-black focus:bg-[#fff] focus:border-black;
  }

  .btn-gray {
    @apply btn bg-[#00000030] border-[#00000030] text-black hover:bg-[#00000030] hover:text-black hover:border-[#00000030] uppercase font-bold h-[40px];
    @apply focus:text-black focus:bg-[#00000030]  focus:border-[#00000030] focus:border-black;
  }

  .btn-transparent {
    @apply btn bg-[#ffffff30] border-[#ffffff30] text-white hover:bg-[#ffffff30] hover:text-white hover:border-[#ffffff30] uppercase font-bold h-[40px];
    @apply focus:text-white focus:bg-[#ffffff30]  focus:border-[#ffffff30] focus:border-black;
  }

  .button {
    @apply btn bg-[#000] border-[#000] text-white hover:bg-[#3A3A3A] hover:text-white hover:border-[#3A3A3A] text-base h-[52px] rounded-[10px];
    @apply focus:text-white focus:bg-[#3A3A3A] focus:border-[#000] focus:border-black;
  }

  .button[disabled] {
    @apply bg-[#F5F5F5] border-[#F5F5F5] text-[#0000003D] hover:bg-[#F5F5F5] hover:text-[#0000003D] hover:border-[#F5F5F5];
    @apply focus:text-[#0000003D] focus:bg-[#F5F5F5] focus:border-[#F5F5F5] focus:border-[#F5F5F5];
  }

  .button.blue {
    @apply bg-[#194CFF] border-[#194CFF] hover:bg-[#194CFF] hover:border-[#194CFF];
    @apply focus:bg-[#194CFF] focus:border-[#194CFF];
  }

  .button.transparent {
    @apply bg-transparent text-black border-[#00000030] hover:bg-transparent hover:border-[#00000030] h-[40px] ;
    @apply focus:bg-transparent focus:border-[#00000030];
  }
}

.votes .vote {
  @apply inline-block bg-[#BEBEBE] w-4 h-4 rounded-full;
}

.votes .vote.checked {
  background-image: url('~/public/images/icons/check.svg');
  background-repeat: no-repeat;
  background-position: center center;
}
